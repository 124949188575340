import { Lang } from 'Roblox';
import angular from 'angular';

const aliases = angular.module('aliases', ['aliasesAppTemplates', 'modal']).config([
  'languageResourceProvider',
  function(languageResourceProvider) {
    if (Lang.ProfileResources) {
      languageResourceProvider.setLanguageKeysFromFile(Lang.ProfileResources);
    }
  }
]);

export default aliases;
