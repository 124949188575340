import { CurrentUser, EventStream } from 'Roblox';
import aliasesModule from '../aliasesModule';

function aliasesContainerController(
  $scope,
  $log,
  $rootScope,
  $window,
  aliasesLayoutService,
  aliasesResources,
  $uibModal,
  contactsService,
  aliasesFriendsService
) {
  'ngInject';

  $scope.changeAlias = () => {
    const modalUrl = aliasesResources.modals.changeAlias.templateUrl;
    const injectedData = {
      layout: $scope.layout,
      library: $scope.library
    };
    const modalParams = {
      animation: false,
      templateUrl: modalUrl,
      controller: aliasesResources.modals.changeAlias.controllerName,
      resolve: {
        injectedData
      }
    };
    EventStream.SendEvent('userTag', 'attempt', {
      userId: $scope.library.currentUserId,
      targetUserId: $scope.library.currentProfileUserId,
      alias: null
    });
    $uibModal.open(modalParams).result.then(
      function () {},
      function () {}
    );
  };

  $scope.retrieveCurrentProfileUserId = function () {
    const pathnameWithNumber = $window.location.pathname.match(/[0-9]+/);
    if (pathnameWithNumber) {
      return pathnameWithNumber[0];
    }
    return null;
  };

  $scope.setup = function () {
    const profileUserId = parseInt($scope.retrieveCurrentProfileUserId());
    const currentUserId = parseInt(CurrentUser.userId);

    $scope.layout = { ...aliasesLayoutService.aliasesLayout };
    $scope.layout.maxCharactersForUserTag = aliasesResources.maxCharactersForUserTag;

    $scope.library = { ...aliasesLayoutService.defaultLibrary };
    $scope.library.currentProfileUserId = profileUserId;
    $scope.library.currentUserId = currentUserId;
    $scope.library.isProfileUserNotCurrentUser =
      currentUserId && !isNaN(profileUserId) && profileUserId !== currentUserId;
  };

  $scope.init = function () {
    $scope.setup();

    if ($scope.library.isProfileUserNotCurrentUser) {
      contactsService
        .getAliasesGuacConfig()
        .then(aliasesGuacConfig => {
          const { areAliasesEnabled = false } = aliasesGuacConfig;
          $scope.aliasesEnabled = areAliasesEnabled;

          if ($scope.aliasesEnabled) {
            aliasesFriendsService
              .getFriendsStatus($scope.library.currentUserId, [$scope.library.currentProfileUserId])
              .then(
                function success(result) {
                  $rootScope.isAliasesLoaded = true;
                  if (result && result.data) {
                    const friendsStatus = result.data;
                    for (const idx in friendsStatus) {
                      if (friendsStatus[idx].status === aliasesResources.friendsStatus.friends) {
                        $scope.layout.isAliasesShown = true;
                      }
                    }
                    if ($scope.layout.isAliasesShown) {
                      contactsService
                        .getUserTags($scope.library.currentProfileUserId)
                        .then(function success(result) {
                          if (result && result.length > 0) {
                            result.forEach(function (tagResult) {
                              if (tagResult.targetUserId === $scope.library.currentProfileUserId) {
                                const userTag = tagResult.targetUserTag;
                                $scope.library.currentUserTag = userTag;
                                $scope.library.userTag = userTag;
                              }
                            });
                          }
                        });
                    }
                  }
                },
                function error() {
                  $rootScope.isAliasesLoaded = true;
                }
              );
          }
        })
        .catch(() => {
          $scope.aliasesEnabled = false;
        }).finally(() => {
          $rootScope.isAliasesLoaded = true;
        });
    } else {
      $rootScope.isAliasesLoaded = true;
    }
  };

  $scope.init();
}
aliasesModule.controller('aliasesContainerController', aliasesContainerController);

export default aliasesContainerController;
